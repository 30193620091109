<template>
    <v-card color="white" class="elevation-4 pa-4" style="border-radius: 10px">
        <h3 class=" teal--text">How to withdraw money from Petty Cash</h3>
        <div>
            In a digital age it is important to know you can still access cash when you need it. And whether it is from your account, nearest Petty Cash Point, or in-store cashback—there are plenty of ways to withdraw money.
            Wherever you are, at home or abroad, withdrawing or depositing physical funds could not be simpler. Discover our guide on how to withdraw money from an Petty Cash point near you, and swiftly withdraw euros while you’re on the go.
        </div>
        
        <br>
        <h3 class=" teal--text">The different means of receiving your withdrawal </h3>
        <div>
            In a digital age it is important to know you can still access cash when you need it. And whether it is from your account, nearest Petty Cash Point, or in-store cashback—there are plenty of ways to withdraw money.
            Wherever you are, at home or abroad, withdrawing or depositing physical funds could not be simpler. Discover our guide on how to withdraw money from an Petty Cash point near you, and swiftly withdraw euros while you’re on the go.
        </div>
        
        <br>
        <h3 class=" teal--text">How long does it take Petty Cash</h3>
        <div>
            In a digital age it is important to know you can still access cash when you need it. And whether it is from your account, nearest Petty Cash Point, or in-store cashback—there are plenty of ways to withdraw money.
            Wherever you are, at home or abroad, withdrawing or depositing physical funds could not be simpler. Discover our guide on how to withdraw money from an Petty Cash point near you, and swiftly withdraw euros while you’re on the go.
        </div>
    </v-card>
</template>

<script>
    export default {
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Withdrawal Information"
            this.$store.commit('setCloseURL', "/savings")
        },
    }
</script>
